<template>
  <div class="bg">
    <div class="top">
      <img src="../../../assets/img/inquiry_bg.png" alt="" />
    </div>
    <div class="bot">
      <div class="box">
        <img src="../../../assets/img/privatedoctor_1.png" alt="" />
        <div>
          <h1>随时问诊</h1>
          <p>联系医生，线上快速沟通</p>
        </div>
      </div>
      <div class="box">
        <img src="../../../assets/img/privatedoctor_2.png" alt="" />
        <div>
          <h1>就医帮助</h1>
          <p>线下就医不用慌，医生给你指导</p>
        </div>
      </div>
      <div class="box">
        <img src="../../../assets/img/privatedoctor_3.png" alt="" />
        <div>
          <h1>诊前后沟通</h1>
          <p>检查 治疗 用药 康复指导</p>
        </div>
      </div>
      <div class="btnbox">
        <van-button round type="info" size="large" @click="gonav('hospitallist')" color='rgba(29,174,252,1)'
          >选择你的私人医生</van-button
        >
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      
    }
  },
  methods: {
    gonav(url){
      this.$router.push({ name: url });
    },
  },
};
</script>
<style lang="less" scoped>
.bg {
  position: relative;
  .top {
    height: 250px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .bot {
    width: 343px;
    height: 379px;
    background: #ffffff;
    border-radius: 8px;
    position: absolute;
    left: 16px;
    top: 175px;
    padding: 24px;
    .box {
      display: flex;
      margin-bottom: 29px;
      img {
        display: block;
        width: 48px;
        height: 48px;
        margin-right: 16px;
      }
      h1 {
        font-size: 18px;
        font-weight: 400;
        margin-bottom: 8px;
      }
    }
    .btnbox{
      margin-top: 40px;
    }
  }
}
</style>
